export const extremetube = {
    brandName: "extremetube",
    logo:
        "/logos/extremetube.png",
    page: 1,
    search: "",
    category: "",
    queryString: "",
    allCategories: []
};
