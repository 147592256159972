export const tube8 = {
    brandName: "tube8",
    logo:
        "/logos/tube8.png",
    page: 1,
    search: "",
    category: "",
    queryString: "",
    allCategories: []
};
