import React from 'react';
import { withRouter } from "react-router-dom";
import TileHeroLink from '@Modules/TileHeroLink';
import Pagination from '@Components/Pagination';
import {abbvNumber} from '@Utils/misc';
import { getURLParam } from "@Utils/url";

const Feed = (props) => {
    const renderFeed = (results) => {
        let items = [];
        const MAX = parseInt(process.env.REACT_APP_MAX_RESULTS_PER_PAGE);
        for(var i = 0; i < MAX && i < results.length; i++){
            let video = results[i];
            items.push(
                <TileHeroLink
                    title={video['title']}
                    src={video['default_thumb']}
                    link={video['url']}
                    key={`${video['id']}_${i}`}
                    srcset={video['thumbs']}
                >
                    <div className="row">
                        <div className="single-detail col">
                            {abbvNumber(video['views'])} Views &middot; <span className="success">{parseFloat(video['rating']).toFixed(1)}%</span>
                        </div>
                        <div className="single-detail col">{video['duration']}</div>
                    </div>
                </TileHeroLink>
            );
        }

        window.scrollTo(0, 0);

        return items;
    }

    return(
        <>
            {(props.results && !props.searching) ? renderFeed(props.results) : <div className="loader"></div>}

            {(props.results) ?
                <center className="clear">
                    <Pagination
                        ariaLabel="footer-pagination"
                        onPageUpdate={() => {}}
                        page={getURLParam(props, 'page')}
                        lastPage="10"
                    />
                </center>
            : null}
        </>
    );
}

export default withRouter(Feed);
