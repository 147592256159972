import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import HookStore from '@Core/HookStore';
import Routing from "@Routes/Routing";
import Header from "@Modules/Header";
import Nav from "@Components/Nav";

class App extends Component {

    componentDidMount() {
        HookStore.doAction( 'init' );

        window.addEventListener('load', () => {
            HookStore.doAction( 'window_loaded' );
        });
    }

    render() {
        return (
            <div className="app clear">
              <Header />
              <Nav />

              <div id="feed-wrapper" className="container col-sm-12 col-md-12 col-lg-10 col-xl-10 float-right clear">
                <div className="feed-wrapper row col float-right">
                  <Routing />
                </div>
              </div>
            </div>
        );
    }
}

export default withRouter(App);
