export const xtube = {
    brandName: "xtube",
    logo:
        "/logos/xtube.png",
    page: 1,
    search: "",
    category: "",
    queryString: "",
    allCategories: []
};
