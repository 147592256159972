import {
    DEFAULT_BRAND,
    RESET_BRAND,
    RESET_ALL_BRANDS,
    UPDATE_ACTIVE_BRAND,
    UPDATE_ALL_BRAND_INFO,
    UPDATE_BRAND_ATTRIBUTE
} from '@Actions/types';
import * as brands from '@Data/brand-defaults';
import HookStore from '@Core/HookStore';

const INITIAL_STATE = {
    activeBrand: DEFAULT_BRAND,
    page: 1,
    search: '',
    category: '',
    queryString: '',
    partnerLogo: null,
    providers: HookStore.applyFilters('all_brands', {
        pornhub: HookStore.applyFilters('pornhub_defaults', brands.pornhub),
        redtube: HookStore.applyFilters('redtube_defaults', brands.redtube),
        tube8: HookStore.applyFilters('tube8_defaults', brands.tube8),
        youporn: HookStore.applyFilters('youporn_defaults', brands.youporn),
        xtube: HookStore.applyFilters('xtube_defaults', brands.xtube),
        spankwire: HookStore.applyFilters('spankwire_defaults', brands.spankwire),
        keezmovies: HookStore.applyFilters('keezmovies_defaults', brands.keezmovies),
        extremetube: HookStore.applyFilters('keezmovies_defaults', brands.extremetube)
    })
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case UPDATE_ACTIVE_BRAND:
            return{
                ...state,
                activeBrand: action.payload,
                partnerLogo: state.providers[action.payload].logo,
                page: state.providers[action.payload].page,
                search: state.providers[action.payload].search,
                category: state.providers[action.payload].category,
                queryString: state.providers[action.payload].queryString
            }

        case UPDATE_ALL_BRAND_INFO:
            return{
                ...state,
                activeBrand: action.payload.activeBrand,
                page: action.payload.page,
                search: action.payload.search,
                category: action.payload.category,
                queryString: action.payload.queryString,
                providers: {
                    ...state.providers,
                    [action.payload.activeBrand]: {
                        ...state.providers[action.payload.activeBrand],
                        page: action.payload.page,
                        search: action.payload.search,
                        category: action.payload.category,
                        queryString: action.payload.queryString
                    }
                }
            }

        case UPDATE_BRAND_ATTRIBUTE:
            return{
                ...state,
                providers: {
                    ...state.providers,
                    [action.payload.brand]: {
                        ...state.providers[action.payload.brand],
                        [action.payload.attribute]: action.payload.value
                    }
                }
            }

        case RESET_BRAND:
            return{
                ...state,
                providers: {
                    [action.payload]: INITIAL_STATE.providers[action.payload]
                }
            }

        case RESET_ALL_BRANDS:
            return{...state, ...INITIAL_STATE}

        default:
            return state;
    }
}
