import {
    SEARCHING,
    SEARCH_BY_QUERY
} from '@Actions/types';
import HookStore from '@Core/HookStore';

const INITIAL_STATE = {
    searchResults: null,
    searching: true
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH_BY_QUERY:
            let payload = HookStore.applyFilters('search_results', action.payload);
            payload.forEach(item => {
                // Filters
                item.duration = HookStore.applyFilters('video_duration', (item.duration || '1:00'));
                item.views = HookStore.applyFilters('video_views', (item.views || 999));
                item.video_id = HookStore.applyFilters('video_id', (item.video_id || ''));
                item.rating = HookStore.applyFilters('video_rating', (item.rating || '99.99'));
                item.ratings = HookStore.applyFilters('video_ratings', (item.ratings || 999));
                item.title = HookStore.applyFilters('video_title', (item.title || ''));
                item.url = HookStore.applyFilters('video_url', (item.url || ''));
                item.default_thumb = HookStore.applyFilters('video_default_thumb', (item.default_thumb || ''));
                item.thumb = HookStore.applyFilters('video_thumb', (item.thumb || ''));
                item.publish_date = HookStore.applyFilters('video_publish_date', (item.publish_date || ''));
                item.thumbs = HookStore.applyFilters('video_thumbs', (item.thumbs || []));
                item.tags = HookStore.applyFilters('video_tags', (item.tags || []));
                item.pornstars = HookStore.applyFilters('video_pornstars', (item.pornstars || []));
                item.categories = HookStore.applyFilters('video_categories', (item.categories || []));
                item.segment = HookStore.applyFilters('video_segment', (item.segment || 'straight'));

                // Attribution
                item.url += (item.url.indexOf('?') > -1) ? '&' : '?';
                item.url += `id=${process.env.REACT_APP_AFFILIATE_ID}&utm_campagin=${process.env.REACT_APP_UTM_CAMPAIGN}`;
                return item;
            })
            return {
                ...state,
                searchResults: payload,
                searching: false
            }

        case SEARCHING:
            return {
                ...state,
                searchResults: null,
                searching: action.payload
            }

        default:
            return state;
    }
}
