export const spankwire = {
    brandName: "spankwire",
    logo:
        "/logos/spankwire.png",
    page: 1,
    search: "",
    category: "",
    queryString: "",
    allCategories: []
};
