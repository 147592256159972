export const pornhub = {
    brandName: "pornhub",
    logo:
        "/logos/pornhub.png",
    page: 1,
    search: "",
    category: "",
    queryString: "",
    allCategories: []
};
